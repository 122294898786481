<template>
  <div
    id="userPasswordChangeModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi bi-key"></i>
            {{
              $t(
                "Components.PasswordChangeModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="alert alert-info"
            v-html="
              $t(
                'Components.PasswordChangeModal.Warning',
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll('USER_NAME', userData.name)
            "
          ></div>
          <form class="user-password-change" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-12">
              <label for="Password" class="form-label required"
                >{{
                  $t(
                    "Projects.Password",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="password"
                class="form-control"
                id="Password"
                @keyup="validatePassword()"
                @keyup.enter="save"
                v-model="model.password"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12 mt-2">
              <label for="PasswordAgain" class="form-label required"
                >{{
                  $t(
                    "Projects.PasswordAgain",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
                <small class="text-danger" v-if="passwordDontMatch"
                  >({{
                    $t(
                      "BaseModelFields.PasswordDontMatch",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }})</small
                ></label
              >
              <input
                type="password"
                class="form-control"
                @keyup="validatePassword()"
                @keyup.enter="save"
                id="PasswordAgain"
                v-model="passwordAgain"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success btn-user-password-change"
            @click="save"
          >
            <span>
              <i class="bi bi-save"></i>
              {{
                $t(
                  "BaseModelFields.Yes",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <button
            type="button"
            class="btn btn-danger btn-user-password-change-cancel"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x"></i>
            {{
              $t(
                "BaseModelFields.No",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  mame: "PasswordChangeModal",
  props: ["userData"],
  watch: {
    userData() {
      this.setDefaultProperties();
    },
  },
  data() {
    return {
      errors: [],
      model: {
        clientUrl: `${window.location.origin}/#/`,
        password: null,
      },
      passwordDontMatch: false,
      passwordAgain: null,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-user-password-change, .btn-user-password-change-cancel",
    };
  },
  methods: {
    setDefaultProperties() {
      this.errors = [];
      this.model = {
        clientUrl: `${window.location.origin}/#/`,
        password: null,
      };
      this.passwordDontMatch = false;
      this.passwordAgain = null;
    },
    validatePassword() {
      this.passwordDontMatch = this.model.password !== this.passwordAgain;
      if (this.passwordDontMatch) {
        $("#PasswordAgain").addClass("is-invalid");
        $("#PasswordAgain").removeClass("is-valid");
      } else {
        $("#PasswordAgain").removeClass("is-invalid");
        $("#PasswordAgain").addClass("is-valid");
      }
    },
    save() {
      var form = $(".user-password-change");
      form.addClass("was-validated");
      if (!form[0].checkValidity() || this.passwordDontMatch) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      var button = $(String.format(".{0}", "btn-user-password-change")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = { ...this.model };
      model.publicId = this.userData.publicId;
      model.password = this.$root.encrypt(model.password, true);

      this.errors = [];
      this.$prodGatewayAxios
        .post("/PasswordChange", model)
        .then((r) => {
          var response = r.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (response.isOk) {
            $(".btn-user-password-change-cancel").trigger("click");
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            this.errors.push(this.$root.getMessageByKey(response.message));
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
